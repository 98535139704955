import { template as template_482336ce8dd8465b8e59fae0e3005507 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_482336ce8dd8465b8e59fae0e3005507(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
