import { template as template_82f536730e804c7d8a8b859eb5429e7f } from "@ember/template-compiler";
const FKLabel = template_82f536730e804c7d8a8b859eb5429e7f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
