import { template as template_181793cdd56446a1a35dec18e9464c2a } from "@ember/template-compiler";
const FKControlMenuContainer = template_181793cdd56446a1a35dec18e9464c2a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
